import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import ym from "react-yandex-metrika"

class Prices extends React.Component {

  componentDidMount() {
      ym("hit", "/prices");

    (function(w, d, u) {
      var s = d.createElement("script")
      s.async = true
      s.src = u + "?" + (Date.now() / 180000 | 0)
      var h = d.getElementsByTagName("script")[0]
      h.parentNode.insertBefore(s, h)
    })(window, document, "https://cdn-ru.bitrix24.ru/b10910472/crm/form/loader_3.js")
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Прайс-лист - Исполиния</title>
          <meta name="description"
                content="Базовый прайс-лист на продукцию RBM
Прайс-лист компании ИСПОЛИНИЯ, официального дилера RBM в России" />

          <meta property="og:title" content="Базовый прайс-лист" />
          <meta property="og:description"
                content="Базовый прайс-лист на продукцию RBM
Прайс-лист компании ИСПОЛИНИЯ, официального дилера RBM в России" />
        </Helmet>

        <Header />
        <main>
          <Container>
            <div>
              <div id="buttons">
                <Link
                  to="../price-list.pdf" download>
                  <button className="col-md">Скачать прайс лист</button>
                </Link>

                <script data-b24-form="click/3/cdjhv2" data-skip-moving="true" />
                <a href="#">
                  <button className="col-md">Запросить скидку</button>
                </a>
              </div>
            </div>
            <iframe title="Базовый прайс лист" src="../price-list.pdf" width="100%" height="1000px">К сожалению, ваш браузер не
              поддерживает отображение
              каталога на
              сайте. Пожалуйста, скачайте его по ссылке выше.
            </iframe>
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

export default Prices